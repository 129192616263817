import { useLastFM } from 'use-last-fm';

export const Music = () => {    
    const lastFM = useLastFM('tvut', 'b1f6cdc08cd429e67f6af923e3279aa9');
    return (
        <>
            {lastFM.status === "playing" && (
                <>
                    <div className='music-container'>
                        <img src={lastFM.song.art}/>
                        <p>I'm listening to {lastFM.song.name} by {lastFM.song.artist} on Spotify.</p>
                    </div>
                </>
            )}
        </>
    )
}
export default Music